<template>
  <div class="main-box" style="display: flex">
    <div class="wrapper">
      <div class="item" :class="{'active': pid==0}" @click="chooseMenu(0)">全部</div>
      <div class="item" v-for="(item,index) in menuList" :key="index" :class="{'active': pid==item.id}" @click="chooseMenu(item.id)">
        <div>{{ item.title }}</div>
        <div class="ico">
          <i class="el-icon-more"></i>
          <div class="menu">
            <div class="it" @click="editMenu(item)">编辑</div>
            <div class="it" @click="delItem(item.id)">删除</div>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 0 20px;">
      <div class="mg-bt-10">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu(1)">添加分类</el-button>
        <el-button type="success" size="small" icon="el-icon-plus" @click="addMenu(2)" v-if="menuList && menuList.length > 0">添加标签</el-button>
        <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除标签</el-button>
      </div>
      <el-table
          :data="tableData"
          ref="rfTable"
          row-key="id"
          style="width: 800px;"
          size="mini"
          default-expand-all>
        <el-table-column
            type="selection"
            align="center"
            width="55">
        </el-table-column>
        <el-table-column
            prop="pid_str"
            label="分类名称">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标签名称">
        </el-table-column>
        <el-table-column
            prop="ordering"
            label="排序">
        </el-table-column>
        <el-table-column
            prop="ftime"
            label="添加时间"
            align="center"
            width="140">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center"
            width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 30, 40]"
          :total="search.total">
      </el-pagination>
    </div>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="所属分类" :label-width="formLabelWidth" v-if="type==2">
          <el-select v-model="form.pid" placeholder="请选择分类">
            <el-option :label="item.title" :value="item.id" v-bind:key="index" v-for="(item, index) in menuList"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '',
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: '',
        ordering: '',
        title: ''
      },
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      formLabelWidth: '120px',
      tableData: [],
      menuList: [],
      type: 1,
      pid: 0
    };
  },
  created() {
    this.getMenu()
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    chooseMenu(id) {
      this.pid = id
      this.getList()
    },
    getMenu() {
      this.$api.member.memberLabelIndex({page_size:100}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.menuList = res.data.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getList() {
      this.showLoading()
      var param = {
        page: this.search.current_page,
        page_size: this.search.limit,
        keywords: this.search.keywords,
        type: 2,
        pid: this.pid==0 ? -1 : this.pid
      }
      this.$api.member.memberLabelIndex(param, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.tableData = res.data.data
          this.search.current_page = res.data.current_page
          this.search.total = res.data.total
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      this.form = JSON.parse(JSON.stringify(item))
      this.type = item.pid>0 ? 2 : 1
      this.menuTitle = item.pid>0 ? '编辑分类' : '编辑标签'
      this.dialogFormVisible = true
    },
    addMenu(id) {
      this.type = id
      this.form.id = 0
      this.form.pid = ''
      this.form.title = ''
      this.form.ordering = ''
      this.menuTitle = id==1 ? '添加分类' : '添加标签'
      this.dialogFormVisible = true
    },
    saveMenu() {
      var param = {
        id: this.form.id,
        title: this.form.title,
        pid: this.form.pid,
        ordering: this.form.ordering
      }
      if(this.type==2 && !param.pid) {
        this.fail('请选择所属分类')
        return false
      }
      if(this.form.id == 0) {
        this.$api.member.memberLabelAdd(param,res => {
          if(res.errcode == 0) {
            this.dialogFormVisible = false
            this.getMenu()
            this.getList()
            this.success(res.errmsg)
          } else {
            this.fail(res.errmsg)
          }
        })
      } else {
        this.$api.member.memberLabelEdit(param,res => {
          if(res.errcode == 0) {
            this.dialogFormVisible = false
            this.getMenu()
            this.getList()
            this.success(res.errmsg)
          } else {
            this.fail(res.errmsg)
          }
        })
      }
    },
    delItem(id) {
      var that = this
      this.$confirm('确认要删除这个分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.member.memberLabelDel({ids: [id]},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getMenu()
            if(id == that.pid) {
              that.pid = 0
            }
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.member.memberLabelDel({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
